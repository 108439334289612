#section-home{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}


#section-home article div{
    margin-bottom: 0rem;
}

#section-home article {
  width: 45%;
}

#section-home article img {
    width: 45vw;
}

#section-home article:nth-child(1){
    z-index: 10 !important;
}
.pdf-viewer-container {
    background-color: #fff9e7; /* Adjust the background to match your page's color */
    padding: 0;
    margin: 0;
    overflow: hidden; /* This will help to cut off any unwanted overflow */
    box-shadow: none; /* Remove any shadow or border effects */
  }
  
  .pdf-viewer-container canvas {
    width: 100% !important;
    height: auto !important; /* Adjust based on content aspect ratio */
    margin: 0;
    padding: 0;
    border: none; /* Make sure no borders are adding extra space */
  }