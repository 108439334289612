@import url("https://use.typekit.net/bjm8pea.css");
@import url('https://fonts.googleapis.com/css2?family=Smooch+Sans:wght@600&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /*overflow-x:none; pour supprimer le defilement horizontal*/
}

#html{
    background-color: #fff9e7;
    font-family: laca-text, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #2A2F3B;
    overflow-x: hidden;
}

.scroll-container{
    scroll-behavior: smooth;
}

h1{
    color: #30a17d;
    font-family: 'Smooch Sans', sans-serif;
    font-size: 8rem;
    line-height:6rem;
    padding: 1rem;
    text-align: right;
    margin-bottom: 1rem;
}

h2{
    display: inline;
    color: #010101;
    padding: 0.5rem;
}

h2:nth-child(odd){
    border-left: 0.2rem solid #ff5757;
    border-right: none;
    border-bottom: 0.2rem solid #ff5757; 
}

h2:nth-child(even){
    border-right: 0.2rem solid black;
    border-left: none;
    border-bottom: 0.2rem solid black; 
}

h3{
    color: #000000;
    font-size: 2rem;
}

.right{
    text-align: right;
}

.center{
    text-align: center;
}

li{
    list-style: none;
}

#header{
    padding: 2rem 7rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    color: #ff5757;
    font-family: 'Smooch Sans', sans-serif;
}
#header nav {
    width: 60vw;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
}

#header nav figure img {
    height: 10rem;
}
#header nav ul{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    font-size: 1.5rem;
    position: relative;;
}

a {
    text-decoration: none;      /* Supprime le soulignement par défaut des liens */
    color: #000000;             /* Définit la couleur du texte du lien en noir */
    scroll-behavior: smooth;    /* Assure un défilement doux pour les ancres dans la page */
    font-size: 2.4rem;          /* Augmente la taille de la police des liens */
    font-weight: bold;          /* Rend le texte des liens en gras */
}

b{
    text-decoration: none;
    color: #ff5757;
    scroll-behavior: smooth;
}

#header nav ul li a:hover{
    border-bottom: 0.2rem solid #30a17d;
}

#contactLink::before{
    content: "Cliquez et l'email sera copié dans votre presse-papier.";
    position: absolute;
    right: 0rem;
    top: 3rem;
    font-size: 1rem;
    color: #545455;
    opacity: 0;
}

#copied::before{
    content: "infos@6kmbierges.be a été copié !";
    position: absolute;
    right: 0rem;
    top: 3rem;
    font-size: 1rem;
    color: #545455;
    opacity: 1;
}

#contactLink:hover::before{
    opacity: 1;
}

a:hover{
    cursor: pointer;
}

.btn {
    background-color: #ff5757;  /* Couleur de fond du bouton */
    color: #fff9e7;             /* Couleur du texte */
    padding: 1rem 3rem;         /* Augmentation du padding pour un bouton plus grand */
    font-family: 'Smooch Sans', sans-serif; /* Police du texte */
    font-size: 2rem;            /* Augmentation significative de la taille de la police */
    text-align: center;         /* Centrage du texte à l'intérieur du bouton */
    margin: 0 auto; 
    margin-left: calc(50% - 8px);            /* Centrage du bouton horizontalement */
    display: block;             /* Assure que le bouton soit centré seul sur sa ligne */
    width: 50%;                 /* Définit une largeur fixe pour le bouton, ajustez selon le besoin */
    border-radius: 8px;         /* Ajoute des bords arrondis au bouton */
    cursor: pointer;            /* Change le curseur pour indiquer que c'est un élément cliquable */
    transition: background-color 0.3s; /* Transition douce pour les changements de couleur de fond */
}

/* Pour un effet interactif lors du survol du bouton */
.btn:hover {
    background-color: #ff6a6a;  /* Légère variation de couleur au survol */
}


.btn:hover{
    background-color: #ffffff;
    color: #ff5757;
}

.right{
    text-align: right;
    padding-right: 4rem;
}

section{
    width: 80%;
    margin: auto;
    margin-top: 5rem;
}

em{
    color: #ff5757;
    font-weight: 600;
}

#section-inscription{
    background-color: rgb(42, 47, 59,0.5);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top:0;
    right:0;
    margin-top: 0;
    z-index: 30;
}

#section-inscription article{
    position: fixed;
    top:0;
    right:0;
    z-index: 35;
    background-color: #fff9e7;
    border: 0.2rem solid #30a17d;
    padding: 1.5rem;
    margin: 10%;
}

footer{
    margin-top: 5rem;
    padding: 3rem 10rem;
    background-color: #2A2F3B;
    color: #fff9e7;

    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

footer section{
    margin-top: 0rem;
}

footer section:nth-child(1){
    width: 15vw !important;
}
 footer section:nth-child(2){
    width: 40vw;
}

footer section:nth-child(3){
    width: 35vw;
} 


footer nav ul{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    width: 100%;
    max-height: 7rem;
}

footer nav ul li{
    padding: 0.7rem;
}

.hidden{
    display: none;
}
.small-red-link {
    font-size: 14px;  /* Taille plus petite */
    color: #ff5757;       /* Couleur rouge */
}

.centered-image {
    display: block;
    margin: 0 auto; /* Centrage horizontal */
    width: 100%; /* Cela prendra la largeur du conteneur jusqu'à un maximum de 500 pixels */
    height: auto; /* Maintient le ratio d'aspect */
}

