#section-sponsors article{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 80%;
    margin: auto;
    padding-top: 2rem;
    align-items: center;
}

#section-sponsors article figure{
    padding: 1.5rem;
    width: 30%;
}

#section-sponsors article figure img{
    width: 80%;
    margin: auto;
}

#sponsors-start{
    width: 60%;
    margin: auto;
}

.display-tarif{
    width: 100%;
    max-width: 600px;
    border: 4px solid #2F6D30; /* 4px est l'épaisseur du cadre, et 'green' est la couleur */
    display: block; /* Assure que l'image s'affiche comme un bloc, permettant au centrage de fonctionner */
    margin: auto; /* Centre l'image horizontalement */
}

.centered-image-sponso {
    border-radius: 20px; /* Arrondit les coins de l'image */
    display: block;
    margin: auto;
    width: 500px; /* Assurez-vous que cette valeur correspond à vos besoins */
    height: auto;
}
