@media only screen and (max-width: 450px) {
    #header {
        padding: 0.8rem;
        flex-flow: column nowrap;
    }
    #header nav {
        display: none;
    }

    #header figure img{
       height: 120px;
       width: auto;
    }

    section{
        width: 95%;
        margin-top: 1rem;
        margin-bottom: 4rem;
    }

    #section-home, #section-about article, #section-sponsors article, footer nav ul, footer, #section-trajet{
        flex-flow: column wrap;
    }

    #section-home article {
        width: 98%;
      }

      #section-home article p, h1 {
        padding: 0;
      }

      h1{
        font-size: 7rem;
      }

      h2{
        font-size: 2rem;
      }

      .btn{
        margin-left: 20%;
        padding: 1rem 3rem;
        font-size: 1.5rem;
      }

      #section-home article img {
        width: 90vw;
        margin-left: 0rem;
    }

    #section-about .middle, footer section:nth-child(3),footer section:nth-child(2) {
        width: 95vw;
    }

    #section-about article div,#section-sponsors article figure {
        padding: 1rem;
        width: 100%;
    }

    #section-trajet article {
      height: 20rem;
      width: 95%;
    }

    .map-container {
      width: 90vw !important;
  }
  .mapboxgl-canvas{
    width: 100vw !important;
    height: auto !important;
  }

  #section-trajet article a{
    width: 70% !important;
  }

  #section-sponsors article figure {
    display: flex;
    align-items: center;
  }

  #section-trajet {
    flex-direction: column-reverse;
}

#section-inscription article {
    width: 300px;
    height: 90vh;
    overflow-y: scroll;
    scrollbar-color: rebeccapurple green;
    scrollbar-width: thin;
}

    footer {
        margin-top: 5rem;
        padding: 2rem;
    }

    footer section:nth-child(2){
        text-align: center;
    }

    footer section {
      width: 100% !important;
  
} 
footer section:nth-child(1){
  width: 100% !important;
}

footer section figure {
  display: flex;
  justify-content: center;
}
#sponsors-start img {
  width: 100%; /* Utilise toute la largeur disponible */
  height: auto; /* Ajuste la hauteur automatiquement pour maintenir les proportions */
}

}

